import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrWishlistItemProductFragmentDoc } from './fdr-wishlist-product.generated';
import { FdrWishlistProductOfferFragmentDoc } from './fdr-wishlist-product-offer.generated';
export type FdrWishlistFragment = {
  __typename?: 'FdrWishlist';
  id: string;
  name: string;
  active: boolean;
  productsAmount: number;
  shareUrl?: string | null;
  wishlistItems?: Array<{
    __typename?: 'FdrWishlistItem';
    source?: string | null;
    product:
      | {
          __typename: 'FdrCruise';
          name: string;
          id: string;
          hero?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
          summaryFeatureGroups?: Array<{
            __typename?: 'FdrProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'FdrProductFeature';
              id: string;
              name: string;
              enabled: boolean;
            }>;
          }> | null;
          destination: {
            __typename?: 'FdrDestination';
            name: string;
            id: string;
            country: { __typename?: 'FdrCountry'; name: string; id: string };
          };
          hubData?: {
            __typename?: 'FdrHubProductData';
            taRating: number;
            taReviews: number;
          } | null;
          includedInPrice?: {
            __typename?: 'FdrTitleText';
            title: string;
            text: string;
          } | null;
          webMeta: {
            __typename?: 'FdrProductWebMeta';
            offersWebMeta?: {
              __typename?: 'FdrMeta';
              title: string;
              description: string;
              index: boolean;
              noFollow: boolean;
              sitemap: boolean;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
        }
      | {
          __typename: 'FdrHotel';
          rating: number;
          mapZoom?: number | null;
          name: string;
          id: string;
          hero?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
          summaryFeatureGroups?: Array<{
            __typename?: 'FdrProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'FdrProductFeature';
              id: string;
              name: string;
              enabled: boolean;
            }>;
          }> | null;
          descriptionFeatureGroups?: Array<{
            __typename?: 'FdrProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'FdrProductFeature';
              id: string;
              name: string;
              enabled: boolean;
            }>;
          }> | null;
          resort: {
            __typename?: 'FdrResort';
            name: string;
            id: string;
            destination: {
              __typename?: 'FdrDestination';
              name: string;
              id: string;
              country: { __typename?: 'FdrCountry'; name: string; id: string };
            };
          };
          tripAdvisorReviews?: {
            __typename?: 'FdrTripAdvisorReviewsResponse';
            totalRating?: number | null;
            numReviews?: number | null;
          } | null;
          coordinates?: {
            __typename?: 'FdrCoordinates';
            latitude: number;
            longitude: number;
          } | null;
          flags?: { __typename?: 'FdrHotelFlags'; top: boolean } | null;
          webMeta: {
            __typename?: 'FdrProductWebMeta';
            offersWebMeta?: {
              __typename?: 'FdrMeta';
              title: string;
              description: string;
              index: boolean;
              noFollow: boolean;
              sitemap: boolean;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
        }
      | {
          __typename: 'FdrRoundTrip';
          name: string;
          id: string;
          hero?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
          summaryFeatureGroups?: Array<{
            __typename?: 'FdrProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'FdrProductFeature';
              id: string;
              name: string;
              enabled: boolean;
            }>;
          }> | null;
          destination: {
            __typename?: 'FdrDestination';
            name: string;
            id: string;
            country: { __typename?: 'FdrCountry'; name: string; id: string };
          };
          hubData?: {
            __typename?: 'FdrHubProductData';
            taRating: number;
            taReviews: number;
          } | null;
          includedInPrice?: {
            __typename?: 'FdrTitleText';
            title: string;
            text: string;
          } | null;
          webMeta: {
            __typename?: 'FdrProductWebMeta';
            offersWebMeta?: {
              __typename?: 'FdrMeta';
              title: string;
              description: string;
              index: boolean;
              noFollow: boolean;
              sitemap: boolean;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
        }
      | {
          __typename: 'FdrAdventureTravel';
          name: string;
          id: string;
          webMeta: {
            __typename?: 'FdrProductWebMeta';
            offersWebMeta?: {
              __typename?: 'FdrMeta';
              title: string;
              description: string;
              index: boolean;
              noFollow: boolean;
              sitemap: boolean;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
        };
    trackedOffer: {
      __typename?: 'FdrTrackedProductOffer';
      status: Types.FdrProductOfferStatus;
      latest?: {
        __typename?: 'FdrProductOffer';
        id: string;
        departureDate: any;
        returnDate: any;
        duration: number;
        transferIncluded: boolean;
        travelType: Types.FdrProductTravelType;
        waitingScreenImage: string;
        provider?: string | null;
        flightAlternativesAvailable: boolean;
        webMeta: {
          __typename?: 'FdrSearchResultWebMeta';
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        };
        pricePerPerson: {
          __typename?: 'FdrPrice';
          value: number;
          currency: string;
        };
        priceTotal: {
          __typename?: 'FdrPrice';
          value: number;
          currency: string;
        };
        rooms: Array<{
          __typename?: 'FdrProductOfferRoom';
          adults: number;
          childrenDobs?: Array<any> | null;
          mealDescription: string;
          roomDescription: string;
          bookingCode: string;
          mealType: string;
          roomType: string;
        }>;
        transferPrice?: {
          __typename?: 'FdrPrice';
          value: number;
          currency: string;
        } | null;
        forwardFlight?: {
          __typename?: 'FdrFlight';
          duration: number;
          overnight: boolean;
          baggageInfo?: Array<string> | null;
          segments: Array<{
            __typename?: 'FdrFlightSegment';
            flightNumber: string;
            flightClass: Types.FdrFlightClass;
            duration: number;
            arrivalDate: any;
            arrivalTime: any;
            baggageIncluded: boolean;
            baggageInfo?: Array<string> | null;
            departureDate: any;
            departureTime: any;
            airportWaiting?: number | null;
            airline: { __typename?: 'FdrAirline'; iata: string; name: string };
            arrivalAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
            departureAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
          }>;
        } | null;
        returnFlight?: {
          __typename?: 'FdrFlight';
          duration: number;
          overnight: boolean;
          baggageInfo?: Array<string> | null;
          segments: Array<{
            __typename?: 'FdrFlightSegment';
            flightNumber: string;
            flightClass: Types.FdrFlightClass;
            duration: number;
            arrivalDate: any;
            arrivalTime: any;
            baggageIncluded: boolean;
            baggageInfo?: Array<string> | null;
            departureDate: any;
            departureTime: any;
            airportWaiting?: number | null;
            airline: { __typename?: 'FdrAirline'; iata: string; name: string };
            arrivalAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
            departureAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
          }>;
        } | null;
      } | null;
      original: {
        __typename?: 'FdrProductOffer';
        id: string;
        departureDate: any;
        returnDate: any;
        duration: number;
        transferIncluded: boolean;
        travelType: Types.FdrProductTravelType;
        waitingScreenImage: string;
        provider?: string | null;
        flightAlternativesAvailable: boolean;
        webMeta: {
          __typename?: 'FdrSearchResultWebMeta';
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        };
        pricePerPerson: {
          __typename?: 'FdrPrice';
          value: number;
          currency: string;
        };
        priceTotal: {
          __typename?: 'FdrPrice';
          value: number;
          currency: string;
        };
        rooms: Array<{
          __typename?: 'FdrProductOfferRoom';
          adults: number;
          childrenDobs?: Array<any> | null;
          mealDescription: string;
          roomDescription: string;
          bookingCode: string;
          mealType: string;
          roomType: string;
        }>;
        transferPrice?: {
          __typename?: 'FdrPrice';
          value: number;
          currency: string;
        } | null;
        forwardFlight?: {
          __typename?: 'FdrFlight';
          duration: number;
          overnight: boolean;
          baggageInfo?: Array<string> | null;
          segments: Array<{
            __typename?: 'FdrFlightSegment';
            flightNumber: string;
            flightClass: Types.FdrFlightClass;
            duration: number;
            arrivalDate: any;
            arrivalTime: any;
            baggageIncluded: boolean;
            baggageInfo?: Array<string> | null;
            departureDate: any;
            departureTime: any;
            airportWaiting?: number | null;
            airline: { __typename?: 'FdrAirline'; iata: string; name: string };
            arrivalAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
            departureAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
          }>;
        } | null;
        returnFlight?: {
          __typename?: 'FdrFlight';
          duration: number;
          overnight: boolean;
          baggageInfo?: Array<string> | null;
          segments: Array<{
            __typename?: 'FdrFlightSegment';
            flightNumber: string;
            flightClass: Types.FdrFlightClass;
            duration: number;
            arrivalDate: any;
            arrivalTime: any;
            baggageIncluded: boolean;
            baggageInfo?: Array<string> | null;
            departureDate: any;
            departureTime: any;
            airportWaiting?: number | null;
            airline: { __typename?: 'FdrAirline'; iata: string; name: string };
            arrivalAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
            departureAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
          }>;
        } | null;
      };
    };
  }> | null;
};

export type FdrWishlistItemFragment = {
  __typename?: 'FdrWishlistItem';
  source?: string | null;
  product:
    | {
        __typename: 'FdrCruise';
        name: string;
        id: string;
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        summaryFeatureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            id: string;
            name: string;
            enabled: boolean;
          }>;
        }> | null;
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          id: string;
          country: { __typename?: 'FdrCountry'; name: string; id: string };
        };
        hubData?: {
          __typename?: 'FdrHubProductData';
          taRating: number;
          taReviews: number;
        } | null;
        includedInPrice?: {
          __typename?: 'FdrTitleText';
          title: string;
          text: string;
        } | null;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            title: string;
            description: string;
            index: boolean;
            noFollow: boolean;
            sitemap: boolean;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename: 'FdrHotel';
        rating: number;
        mapZoom?: number | null;
        name: string;
        id: string;
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        summaryFeatureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            id: string;
            name: string;
            enabled: boolean;
          }>;
        }> | null;
        descriptionFeatureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            id: string;
            name: string;
            enabled: boolean;
          }>;
        }> | null;
        resort: {
          __typename?: 'FdrResort';
          name: string;
          id: string;
          destination: {
            __typename?: 'FdrDestination';
            name: string;
            id: string;
            country: { __typename?: 'FdrCountry'; name: string; id: string };
          };
        };
        tripAdvisorReviews?: {
          __typename?: 'FdrTripAdvisorReviewsResponse';
          totalRating?: number | null;
          numReviews?: number | null;
        } | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          latitude: number;
          longitude: number;
        } | null;
        flags?: { __typename?: 'FdrHotelFlags'; top: boolean } | null;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            title: string;
            description: string;
            index: boolean;
            noFollow: boolean;
            sitemap: boolean;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename: 'FdrRoundTrip';
        name: string;
        id: string;
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        summaryFeatureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            id: string;
            name: string;
            enabled: boolean;
          }>;
        }> | null;
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          id: string;
          country: { __typename?: 'FdrCountry'; name: string; id: string };
        };
        hubData?: {
          __typename?: 'FdrHubProductData';
          taRating: number;
          taReviews: number;
        } | null;
        includedInPrice?: {
          __typename?: 'FdrTitleText';
          title: string;
          text: string;
        } | null;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            title: string;
            description: string;
            index: boolean;
            noFollow: boolean;
            sitemap: boolean;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename: 'FdrAdventureTravel';
        name: string;
        id: string;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            title: string;
            description: string;
            index: boolean;
            noFollow: boolean;
            sitemap: boolean;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      };
  trackedOffer: {
    __typename?: 'FdrTrackedProductOffer';
    status: Types.FdrProductOfferStatus;
    latest?: {
      __typename?: 'FdrProductOffer';
      id: string;
      departureDate: any;
      returnDate: any;
      duration: number;
      transferIncluded: boolean;
      travelType: Types.FdrProductTravelType;
      waitingScreenImage: string;
      provider?: string | null;
      flightAlternativesAvailable: boolean;
      webMeta: {
        __typename?: 'FdrSearchResultWebMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      };
      pricePerPerson: {
        __typename?: 'FdrPrice';
        value: number;
        currency: string;
      };
      priceTotal: { __typename?: 'FdrPrice'; value: number; currency: string };
      rooms: Array<{
        __typename?: 'FdrProductOfferRoom';
        adults: number;
        childrenDobs?: Array<any> | null;
        mealDescription: string;
        roomDescription: string;
        bookingCode: string;
        mealType: string;
        roomType: string;
      }>;
      transferPrice?: {
        __typename?: 'FdrPrice';
        value: number;
        currency: string;
      } | null;
      forwardFlight?: {
        __typename?: 'FdrFlight';
        duration: number;
        overnight: boolean;
        baggageInfo?: Array<string> | null;
        segments: Array<{
          __typename?: 'FdrFlightSegment';
          flightNumber: string;
          flightClass: Types.FdrFlightClass;
          duration: number;
          arrivalDate: any;
          arrivalTime: any;
          baggageIncluded: boolean;
          baggageInfo?: Array<string> | null;
          departureDate: any;
          departureTime: any;
          airportWaiting?: number | null;
          airline: { __typename?: 'FdrAirline'; iata: string; name: string };
          arrivalAirport: {
            __typename?: 'FdrAirport';
            name: string;
            isGroup: boolean;
            iata: string;
            codes?: Array<string> | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              longitude: number;
              latitude: number;
            } | null;
          };
          departureAirport: {
            __typename?: 'FdrAirport';
            name: string;
            isGroup: boolean;
            iata: string;
            codes?: Array<string> | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              longitude: number;
              latitude: number;
            } | null;
          };
        }>;
      } | null;
      returnFlight?: {
        __typename?: 'FdrFlight';
        duration: number;
        overnight: boolean;
        baggageInfo?: Array<string> | null;
        segments: Array<{
          __typename?: 'FdrFlightSegment';
          flightNumber: string;
          flightClass: Types.FdrFlightClass;
          duration: number;
          arrivalDate: any;
          arrivalTime: any;
          baggageIncluded: boolean;
          baggageInfo?: Array<string> | null;
          departureDate: any;
          departureTime: any;
          airportWaiting?: number | null;
          airline: { __typename?: 'FdrAirline'; iata: string; name: string };
          arrivalAirport: {
            __typename?: 'FdrAirport';
            name: string;
            isGroup: boolean;
            iata: string;
            codes?: Array<string> | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              longitude: number;
              latitude: number;
            } | null;
          };
          departureAirport: {
            __typename?: 'FdrAirport';
            name: string;
            isGroup: boolean;
            iata: string;
            codes?: Array<string> | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              longitude: number;
              latitude: number;
            } | null;
          };
        }>;
      } | null;
    } | null;
    original: {
      __typename?: 'FdrProductOffer';
      id: string;
      departureDate: any;
      returnDate: any;
      duration: number;
      transferIncluded: boolean;
      travelType: Types.FdrProductTravelType;
      waitingScreenImage: string;
      provider?: string | null;
      flightAlternativesAvailable: boolean;
      webMeta: {
        __typename?: 'FdrSearchResultWebMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      };
      pricePerPerson: {
        __typename?: 'FdrPrice';
        value: number;
        currency: string;
      };
      priceTotal: { __typename?: 'FdrPrice'; value: number; currency: string };
      rooms: Array<{
        __typename?: 'FdrProductOfferRoom';
        adults: number;
        childrenDobs?: Array<any> | null;
        mealDescription: string;
        roomDescription: string;
        bookingCode: string;
        mealType: string;
        roomType: string;
      }>;
      transferPrice?: {
        __typename?: 'FdrPrice';
        value: number;
        currency: string;
      } | null;
      forwardFlight?: {
        __typename?: 'FdrFlight';
        duration: number;
        overnight: boolean;
        baggageInfo?: Array<string> | null;
        segments: Array<{
          __typename?: 'FdrFlightSegment';
          flightNumber: string;
          flightClass: Types.FdrFlightClass;
          duration: number;
          arrivalDate: any;
          arrivalTime: any;
          baggageIncluded: boolean;
          baggageInfo?: Array<string> | null;
          departureDate: any;
          departureTime: any;
          airportWaiting?: number | null;
          airline: { __typename?: 'FdrAirline'; iata: string; name: string };
          arrivalAirport: {
            __typename?: 'FdrAirport';
            name: string;
            isGroup: boolean;
            iata: string;
            codes?: Array<string> | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              longitude: number;
              latitude: number;
            } | null;
          };
          departureAirport: {
            __typename?: 'FdrAirport';
            name: string;
            isGroup: boolean;
            iata: string;
            codes?: Array<string> | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              longitude: number;
              latitude: number;
            } | null;
          };
        }>;
      } | null;
      returnFlight?: {
        __typename?: 'FdrFlight';
        duration: number;
        overnight: boolean;
        baggageInfo?: Array<string> | null;
        segments: Array<{
          __typename?: 'FdrFlightSegment';
          flightNumber: string;
          flightClass: Types.FdrFlightClass;
          duration: number;
          arrivalDate: any;
          arrivalTime: any;
          baggageIncluded: boolean;
          baggageInfo?: Array<string> | null;
          departureDate: any;
          departureTime: any;
          airportWaiting?: number | null;
          airline: { __typename?: 'FdrAirline'; iata: string; name: string };
          arrivalAirport: {
            __typename?: 'FdrAirport';
            name: string;
            isGroup: boolean;
            iata: string;
            codes?: Array<string> | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              longitude: number;
              latitude: number;
            } | null;
          };
          departureAirport: {
            __typename?: 'FdrAirport';
            name: string;
            isGroup: boolean;
            iata: string;
            codes?: Array<string> | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              longitude: number;
              latitude: number;
            } | null;
          };
        }>;
      } | null;
    };
  };
};

export const FdrWishlistItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrWishlistItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrWishlistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWishlistItemProduct' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackedOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrWishlistProductOffer',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'original' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrWishlistProductOffer',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
        ],
      },
    },
    ...FdrWishlistItemProductFragmentDoc.definitions,
    ...FdrWishlistProductOfferFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrWishlistFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrWishlist' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrWishlist' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productsAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wishlistItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWishlistItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrWishlistItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
